import { css, html } from "lit";
import { setupCustomElement } from "../../lit-util.js";
import WarpElement from "@warp-ds/elements-core";
import { AssetFields } from "contentful";

const NAME = "contentful-image";

export class ContentfulImage extends WarpElement {
	declare entryId: string;
	declare fieldId: string;
	declare imageData: AssetFields;
	declare imageStyle: string;
	declare aspectRatio: boolean;
	declare landscapeMode: string;

	constructor() {
		super();
		this.entryId = "";
		this.fieldId = "";
		this.imageData = {} as AssetFields;
		this.imageStyle = "";
		this.aspectRatio = false;
		this.landscapeMode = "";
	}

	static styles = [
		css`
			* {
				font-family: "Finntype", sans-serif;
			}

			.aspect-img {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
			}

			.aspect-parent {
				position: relative;
				padding-bottom: 56.25%;
			}

			/** add media for mobile */
			@media (max-width: 768px) {
				.aspect-parent {
					padding-bottom: 75%;
				}
			}

			.aspect-parent-landscape {
				padding-bottom: 25%;
			}

			*,:before,:after{--w-rotate:0;--w-rotate-x:0;--w-rotate-y:0;--w-rotate-z:0;--w-scale-x:1;--w-scale-y:1;--w-scale-z:1;--w-skew-x:0;--w-skew-y:0;--w-translate-x:0;--w-translate-y:0;--w-translate-z:0}.aspect-fix{padding-bottom:undefined;position:relative}.aspect-fix>*{width:100%;height:100%;position:absolute;top:0;bottom:0;left:0;right:0}.aspect-img{padding-bottom:undefined;position:relative}.aspect-img>*{width:100%;height:100%;position:absolute;top:0;bottom:0;left:0;right:0}.aspect-parent-landscape{padding-bottom:undefined;position:relative}.aspect-parent-landscape>*{width:100%;height:100%;position:absolute;top:0;bottom:0;left:0;right:0}.aspect-parent{padding-bottom:undefined;position:relative}.aspect-parent>*{width:100%;height:100%;position:absolute;top:0;bottom:0;left:0;right:0}.static{position:static}.object-cover{object-fit:cover}.object-center{object-position:center};
		`,
		...WarpElement.styles,
	] as unknown as never;

	static properties = {
		imageData: { type: Object },
		entryId: { type: String },
		aspectRatio: { type: Boolean },
		fieldId: { type: String },
		landscapeMode: { type: String },
		imageStyle: { type: String },
	};

	getUrlWithProtocol(url: string = "") {
		return url?.startsWith("//") ? `https:${url}` : url;
	}

	render() {
		const { file, title } = this.imageData || {};
		const { url } = file || {};

		const imageHtml = html`<img
			class="object-cover object-center w-full ${this.aspectRatio ? "aspect-img" : ""}"
			src="${this.getUrlWithProtocol(url)}"
			alt="${title}"
			style="${this.imageStyle || ""}"
			data-contentful-entry-id="${this.entryId}"
			data-contentful-field-id="${this.fieldId}"
		/>`;

		if (this.aspectRatio) {
			return html`
				<div
					class="relative aspect-fix aspect-parent ${this.landscapeMode === "true" ? "aspect-parent-landscape" : ""}"
				>
					${imageHtml}
				</div>
			`;
		}
		return imageHtml;
	}
}

setupCustomElement(NAME, ContentfulImage);
