// LitElement that is a paragraph block
import { css, html } from "lit";
import WarpElement from "@warp-ds/elements-core";
import { getPodletMetaData, setupCustomElement } from "../../lit-util.js";
import { PodletMetaData } from "../../../types/types-drEdition.js";
import { AssetFields } from "contentful/dist/types/types/asset.js";
import { CurrentPlatform, getCurrentPlatform } from "../utils/getCurrentPlatform.js";

const NAME = "contentful-sponsor-header";

export class ContentfulSponsorHeader extends WarpElement {
	declare entryId: string;
	declare sponsorName: string;
	declare logoImageData: AssetFields;
	declare metaData: PodletMetaData;
	declare currentPlatform: CurrentPlatform;

	static properties = {
		entryId: { type: String },
		sponsorName: { type: String },
		logoImageData: { type: Object },
	};

	constructor() {
		super();
		this.entryId = "";
		this.sponsorName = "";
		this.logoImageData = {} as AssetFields;
		this.metaData = getPodletMetaData();

		this.currentPlatform = {} as CurrentPlatform;
		getCurrentPlatform(this, (currentPlatform) => {
			this.currentPlatform = currentPlatform;
			this.requestUpdate();
		});
	}

	static styles = [
		css`
			* {
				font-family: "Finntype", sans-serif;
			}

			:host {
				display: block;
			}

			.sponsorLabel {
				font-size: var(--w-font-size-m);
			}

			@media (max-width: 468px) {
				.logo-wrapper {
					max-width: 30rem;
				}
				.sponsorLabel {
					font-size: var(--w-font-size-s);
				}
			}

			#sponsor-header.dark-mode {
				color: white;
			}

			#sponsor-header.dark-mode * {
				color: white;
			}

			#sponsor-header.dark-mode img {
				background: white;
			}
			*,:before,:after{--w-rotate:0;--w-rotate-x:0;--w-rotate-y:0;--w-rotate-z:0;--w-scale-x:1;--w-scale-y:1;--w-scale-z:1;--w-skew-x:0;--w-skew-y:0;--w-translate-x:0;--w-translate-y:0;--w-translate-z:0}.static{position:static};
		`,
		...WarpElement.styles,
	] as unknown as never;

	getImageStyle() {
		if (this.currentPlatform.isMobile) {
			return (
				"max-width: 16rem;" + (this.currentPlatform.platform === "ios" ? " max-height: 6.4rem;" : " max-height: 3.6rem")
			);
		}
		return "max-width: 15rem; max-height: 3rem;";
	}

	getContainerHeight() {
		if (this.currentPlatform.platform === "ios") {
			return "height: 7rem;";
		} else if (this.currentPlatform.platform === "android") {
			return "height: 3.6rem;";
		} else return "height: 5rem;";
	}

	getSponsorImage() {
		return html`<div
			class="logo-wrapper ${this.metaData.darkMode ? "s-bg" : ""} ${this.metaData.landscapeMode ? "s-bg" : ""}"
		>
			<contentful-image
				entryId="${this.entryId}"
				fieldId="logoImageData"
				imageStyle="object-fit: contain; ${this.getImageStyle()}"
				width="auto"
				.imageData="${this.logoImageData}"
			></contentful-image>
		</div>`;
	}

	render() {
		return html`<div
			id="sponsor-header"
			style="${this.getContainerHeight()}"
			class="relative flex items-center justify-between p-8 
				${this.metaData.darkMode ? "dark-mode" : ""}"
		>
			<span class="sponsorLabel block ml-3 text-regular font-bold"
				>${this.i18n.t({
					id: "common.sponsorLabel",
					message: "Annonsørinnhold",
				})}</span
			>
			${this.getSponsorImage()}
		</div>`;
	}
}

setupCustomElement(NAME, ContentfulSponsorHeader);
